export default [
  {
    path: '/user',
    name:'user',
    component: ()=>import('@/views/user/main'),
    meta:{
      title: '用户管理'
    },
    children: [
      {
        path: 'list',
        name:'user_list',
        component: ()=>import('@/views/user/list'),
        meta:{
          title: '用户列表'
        }
      },
      {
        path: 'list/edit',
        name:'user_list_edit',
        component: ()=>import('@/views/user/edit'),
        meta:{
          title: '编辑用户'
        },
      }
    ],
  },
]
