export default [
  {
    path: '/page',
    name:'page',
    component: ()=>import('@/views/page/main'),
    meta:{
      title: '页面管理'
    },
    children: [
      {
        path: 'about',
        name:'page_about',
        component: ()=>import('@/views/page/about/main'),
        meta:{
          title: '关于大赛'
        }
      },
      {
        path: 'racenormal',
        name:'page_racenormal',
        component: ()=>import('@/views/page/racenormal/main'),
        meta:{
          title: '校园赛道'
        }
      },
      {
        path: 'racecommon',
        name:'page_racecommon',
        component: ()=>import('@/views/page/racecommon/main'),
        meta:{
          title: '特别企划'
        }
      },
      {
        path: 'raceenterprise',
        name:'page_raceenterprise',
        component: ()=>import('@/views/page/raceenterprise/main'),
        meta:{
          title: '社会赛道'
        }
      },
      {
        path: 'racespecial',
        name:'page_racespecial',
        component: ()=>import('@/views/page/racespecial/main'),
        meta:{
          title: '专项课题赛事'
        }
      },
      {
        path: 'racemascot',
        name:'page_racemascot',
        component: ()=>import('@/views/page/racemascot/main'),
        meta:{
          title: '吉祥物征集'
        }
      },
      {
        path: 'link',
        name:'page_link',
        component: ()=>import('@/views/page/link/main'),
        meta:{
          title: '联系我们'
        }
      },
    ],
  },
]
