export default [
  {
    path: '/work',
    name:'work',
    component: ()=>import('@/views/work/main'),
    meta:{
      title: '赛事管理'
    },
    children: [
      {
        path: 'race',
        name:'work_race',
        component: ()=>import('@/views/work/race/list'),
        meta:{
          title: '赛事赛道'
        }
      },
      {
        path: 'race/edit',
        name:'work_race_edit',
        component: ()=>import('@/views/work/race/edit'),
        meta:{
          title: '编辑赛事赛道'
        }
      },
      {
        path: 'race/topic',
        name:'work_race_topic',
        component: ()=>import('@/views/work/topic/list'),
        meta:{
          title: '赛事主题'
        }
      },
      {
        path: 'race/topic/edit',
        name:'work_race_topic_edit',
        component: ()=>import('@/views/work/topic/edit'),
        meta:{
          title: '编辑赛事主题'
        }
      },
      {
        path: 'package',
        name:'work_package',
        component: ()=>import('@/views/work/package/list'),
        meta:{
          title: '作品打包'
        }
      },
      {
        path: 'list',
        name:'work_list',
        component: ()=>import('@/views/work/list'),
        meta:{
          title: '作品列表'
        }
      },
      {
        path: 'list/info',
        name:'work_info',
        component: ()=>import('@/views/work/info'),
        meta:{
          title: '作品详情'
        }
      },
      {
        path: 'award',
        name:'work_award',
        component: ()=>import('@/views/work/award/list'),
        meta:{
          title: '获奖作品'
        }
      },
      {
        path: 'award/edit',
        name:'work_award_edit',
        component: ()=>import('@/views/work/award/edit'),
        meta:{
          title: '编辑获奖作品'
        }
      },
    ],
  },
]
