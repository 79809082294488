<template>
  <div id="app">
    <template v-if="isRouterAlive">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"/>
    </template>
  </div>
</template>
<script>
export default {
  data(){
    return {
      isRouterAlive: true,
    }
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods:{
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  }
}
</script>
<style lang="scss">
  @import "css/var";
  @import "~@/css/reset.scss";
  @import "~@/css/style.scss";
  #app {
    min-width: $minWidth;
    font-family: PingFang HK-Regular, PingFang HK;
    background: #fff;
  }
</style>
