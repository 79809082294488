export default [
  {
    path: '/article',
    name:'article',
    component: ()=>import('@/views/article/main'),
    meta:{
      title: '新闻管理'
    },
    children: [
      {
        path: 'list',
        name:'article_list',
        component: ()=>import('@/views/article/list'),
        meta:{
          title: '新闻列表'
        }
      },
      {
        path: 'list/edit',
        name:'article_list_edit',
        component: ()=>import('@/views/article/edit'),
        meta:{
          title: '编辑新闻'
        },
      }
    ],
  },
]
