// import Vue from 'vue';
import { Message } from 'element-ui';

class WarningFn {
  constructor() {
    this.msgFn = Message;
  }

  // 转换内容
  setMessage(text) {
    let msg = '';
    if (text === 'Auth fail') {
      msg = '登录信息已过期,请重新登录';
    } else {
      msg = text;
    }
    return msg;
  }

  open(options) {
    this.msgFn.closeAll();
    return this.msgFn({
      showClose: true,
      message: this.setMessage(options),
      type: 'warning',
      offset: 300,
      duration: 2500,
    });
  }

  msg(options) {
    this.msgFn.closeAll();
    return this.msgFn({
      showClose: true,
      message: this.setMessage(options),
      offset: 300,
      duration: 2500,
    });
  }

  success(options) {
    this.msgFn.closeAll();
    return this.msgFn({
      showClose: true,
      message: this.setMessage(options),
      type: 'success',
      offset: 300,
      duration: 2500,
    });
  }

  close() {
    this.msgFn.closeAll();
  }
}

export const Warning = new WarningFn();
export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$Malert = Warning;
  },
};
