export default [
  {
    path: '/link',
    name:'link',
    component: ()=>import('@/views/link/main'),
    meta:{
      title: '合作方管理'
    },
    children: [
      {
        path: 'university',
        name:'link_university',
        component: ()=>import('@/views/link/university/list'),
        meta:{
          title: '合作院校'
        }
      },
      {
        path: 'university/edit',
        name:'link_university_edit',
        component: ()=>import('@/views/link/university/edit'),
        meta:{
          title: '编辑合作院校'
        }
      },
      {
        path: 'friend',
        name:'link_friend',
        component: ()=>import('@/views/link/friend/list'),
        meta:{
          title: '合作伙伴'
        }
      },
      {
        path: 'friend/edit',
        name:'link_friend_edit',
        component: ()=>import('@/views/link/friend/edit'),
        meta:{
          title: '编辑合作伙伴'
        }
      },
      {
        path: 'brand',
        name:'link_brand',
        component: ()=>import('@/views/link/brand/list'),
        meta:{
          title: '品牌赞助'
        }
      },
      {
        path: 'brand/edit',
        name:'link_brand_edit',
        component: ()=>import('@/views/link/brand/edit'),
        meta:{
          title: '编辑品牌赞助'
        }
      },
      {
        path: 'sponsor',
        name:'link_sponsor',
        component: ()=>import('@/views/link/sponsor/list'),
        meta:{
          title: '主办单位'
        }
      },
      {
        path: 'sponsor/edit',
        name:'link_sponsor_edit',
        component: ()=>import('@/views/link/sponsor/edit'),
        meta:{
          title: '编辑主办单位'
        }
      },
      {
        path: 'co',
        name:'link_co',
        component: ()=>import('@/views/link/co/list'),
        meta:{
          title: '协办单位'
        }
      },
      {
        path: 'co/edit',
        name:'link_co_edit',
        component: ()=>import('@/views/link/co/edit'),
        meta:{
          title: '编辑协办单位'
        }
      },
      {
        path: 'organizer',
        name:'link_organizer',
        component: ()=>import('@/views/link/organizer/list'),
        meta:{
          title: '承办单位'
        }
      },
      {
        path: 'organizer/edit',
        name:'link_organizer_edit',
        component: ()=>import('@/views/link/organizer/edit'),
        meta:{
          title: '编辑承办单位'
        }
      },
      {
        path: 'web',
        name:'link_web',
        component: ()=>import('@/views/link/web/list'),
        meta:{
          title: '相关网站'
        }
      },
      {
        path: 'web/edit',
        name:'link_web_edit',
        component: ()=>import('@/views/link/web/edit'),
        meta:{
          title: '编辑相关网站'
        }
      },
      {
        path: 'execute',
        name:'link_execute',
        component: ()=>import('@/views/link/execute/list'),
        meta:{
          title: '执行单位'
        }
      },
      {
        path: 'execute/edit',
        name:'link_execute_edit',
        component: ()=>import('@/views/link/execute/edit'),
        meta:{
          title: '编辑执行单位'
        }
      },
    ],
  },
]
