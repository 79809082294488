import Vue from 'vue'
import VueRouter from 'vue-router'
import sys from './sys'
import user from './user'
import article from './article'
import config from './config'
import page from './page'
import link from './link'
import work from './work'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name:'index',
    component: ()=>import('@/views/index'),
    meta:{
      title: '重定向中……'
    },
  },
  {
    path: '/home',
    name:'home',
    component: ()=>import('@/views/home'),
    meta:{
      title: '后台首页'
    }
  },
  {
    path: '/login',
    name:'login',
    component: ()=>import('@/views/login'),
    meta:{
      title: '用户登录'
    }
  },
  ...sys,
  ...user,
  ...article,
  ...config,
  ...page,
  ...link,
  ...work,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: to => {
    if (!to.meta.keepAlive) {
      return {
        y: 0
      };
    } else {
      return { x: 0, y: to.meta.savedPosition || 0 };
    }
  },
  routes
})

export default router
