import Vue from "vue";
//import {getUserInfo} from "@/request/api";

const infoModule = {
    namespaced: true,
    state: {
        token:'',
        userInfo:{},
        workInfo:{},
    },
    actions: {
        /*
        async getUserInfo({commit}) {
            const response = await getUserInfo({});
            if (response && response.data) {
                commit("updateUserInfo", response.data);
                return response.data;
            }
        },*/
    },
    mutations: {
        updateToken(state, token) {
            Vue.set(state, "token", token);
        },
        updateUserInfo(state,userInfo){
            Vue.set(state,'userInfo',userInfo)
        },
        updateWorkInfo(state,workInfo){
            console.log(workInfo)
            Vue.set(state,'workInfo',workInfo)
            console.log(state)
        }
    },
    getters: {
        getToken: state => {
            return state.token || '';
        },
        getUserInfoObj:state =>{
            return state.userInfo || {}
        },
        getWorkInfo:state =>{
            return state.workInfo || {}
        }
    }
};

export default infoModule;
