import router from '@/router/index';
import store from '@/store/index';
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const token = store.getters["infoModule/getToken"];
  if(to.meta.needAuth){
    store.dispatch('infoModule/getUserInfo')
  }
  if(to.meta.needAuth && token === ''){
    next({name:'userLogin'})
  }else {
    next()
  }
})
