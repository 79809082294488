import Vue from 'vue'
import Vuex from 'vuex'
import infoModule from "./info";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
let storageMode = window.sessionStorage;
let localStorageMode = window.localStorage;
const plugins = [
  createPersistedState({
    key: "STORAGE",
    storage: storageMode,
    paths: [
      'infoModule.userInfo',
      'infoModule.workInfo'
    ]
  }),
  createPersistedState({
    key: "STORAGE",
    storage: localStorageMode,
    paths: [
      "infoModule.token",
    ]
  })
];
export default new Vuex.Store({
  modules: {
    infoModule
  },
  plugins
})
