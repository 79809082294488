export default [
  {
    path: '/config',
    name:'config',
    component: ()=>import('@/views/config/main'),
    meta:{
      title: '配置管理'
    },
    children: [
      {
        path: 'menu',
        name:'config_menu',
        component: ()=>import('@/views/config/menu'),
        meta:{
          title: '菜单管理'
        }
      },
      {
        path: 'useragreement',
        name:'config_useragreement',
        component: ()=>import('@/views/config/useragreement'),
        meta:{
          title: '编辑用户协议'
        }
      },
      {
        path: 'matchagreement',
        name:'config_matchagreement',
        component: ()=>import('@/views/config/matchagreement'),
        meta:{
          title: '编辑比赛协议'
        }
      },
      {
        path: 'webagreement',
        name:'config_webagreement',
        component: ()=>import('@/views/config/webagreement'),
        meta:{
          title: '编辑网站条款'
        }
      },
      {
        path: 'aboutus',
        name:'config_aboutus',
        component: ()=>import('@/views/config/aboutus'),
        meta:{
          title: '编辑关于我们'
        }
      },
      {
        path: 'help',
        name:'config_help',
        component: ()=>import('@/views/config/help'),
        meta:{
          title: '编辑帮助中心'
        }
      },
      {
        path: 'faq',
        name:'config_faq',
        component: ()=>import('@/views/config/faq'),
        meta:{
          title: '编辑常见问题'
        }
      },
      {
        path: 'email',
        name:'config_email',
        component: ()=>import('@/views/config/email'),
        meta:{
          title: '编辑E-mail'
        }
      },
    ],
  },
]
