import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/plugins/element';
import '@/utils/permission';
import Loading from '@/plugins/loading';
import Warning from '@/plugins/warning';
import ElementUI from 'element-ui';

import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

//样式
import 'tinymce/skins/content/default/content.min.css'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'

//主题
import 'tinymce/themes/silver'

//插件
import 'tinymce/plugins/code' //链接插件
import 'tinymce/plugins/link' //链接插件
import 'tinymce/plugins/image' //图片插件
import 'tinymce/plugins/media' //媒体插件
import 'tinymce/plugins/table' //表格插件
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/quickbars' //快速栏插件
import 'tinymce/plugins/fullscreen' //全屏插件
import 'tinymce/icons/default/icons'
//import '@/assets/tinymce/langs/zh_CN.js'

//import setRem from "@/utils/rem";
import minxinObj from '@/utils/commonMixin';
import 'element-ui/lib/theme-chalk/index.css';
//setRem()
Vue.mixin(minxinObj);
Vue.use(Loading);
Vue.use(Warning);
Vue.use(ElementUI);
Vue.prototype.$tinymce = tinymce // 将全局tinymce对象指向给Vue作用域下
Vue.use(VueTinymce)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
