
const minxinObj = {
    data() {
        return {

        };
    },
    computed: {},
    methods: {
        /**
         *   initComponentsData
         *   功能： 初始化组件内的数据
         *   参数：
         *       exclude  type: Array   说明：要避免被初始化的data对象属性
         *
         * **/
        initComponentsData(exclude = []) {
            const that = this;
            const {$data} = that;
            const $initData = that.$options.data();
            if (!exclude || exclude.length === 0) {
                that.data = Object.assign(that.$data, $initData);
            } else {
                for (const [key] of Object.entries($data)) {
                    if (!(exclude && exclude.includes(key))) {
                        $data[key] = $initData[key];
                    }
                }
            }
        },
        /**
         * scrollToTop
         * 功能: 滑动到顶部
         * 参数: 无
         * **/
        scrollToTop() {
            const drag = 3;
            // 距离顶部的距离
            const gap = document.documentElement.scrollTop || document.body.scrollTop;
            if (gap > 0) {
                window.requestAnimationFrame(this.scrollToTop);
                window.scrollTo(0, gap - gap / drag);
            }
        },
        /**
         * setDocumentTitle
         * 功能: 设置页面title
         * 参数:
         *      str: 页面标题
         * **/
        setDocumentTitle(str) {
            setTimeout(() => {
                //利用iframe的onload事件刷新页面
                let title = document.getElementsByTagName("title")[0];
                title.innerText = str;
                let iframe = document.createElement("iframe");
                iframe.style.visibility = "hidden";
                iframe.style.width = "1px";
                iframe.style.height = "1px";
                iframe.onload = function () {
                    setTimeout(function () {
                        document.body.removeChild(iframe);
                    }, 0);
                };
                document.body.appendChild(iframe);
            }, 0);
        },
        /**
         * pageShowReload
         * 功能: 页面返回需要刷新
         * 参数: 无
         * **/
        pageShowReload() {
            let isPageHide = false;
            window.addEventListener("pageshow", function () {
                if (isPageHide) {
                    window.location.reload();
                }
            });
            window.addEventListener("pagehide", function () {
                isPageHide = true;
            });
        },
        /**
         * pageShowReload
         * 功能: 页面返回需要回退
         * 参数: 无
         * **/
        pageShowBack() {
            let isPageHide = false;
            window.addEventListener("pageshow", function () {
                if (isPageHide) {
                    history.go(-1);
                }
            });
            window.addEventListener("pagehide", function () {
                isPageHide = true;
            });
        },
        /*****页面滑动控制******/
        webNoScroll() {
            const mo = function (e) {
                e.preventDefault();
            };
            document.body.style.overflow = "hidden";
            document.addEventListener("touchmove", mo, false); // 禁止页面滑动
            this.webIsLock = true;
        },
        webCanScroll() {
            const mo = function (e) {
                e.preventDefault();
            };
            document.body.style.overflow = ""; // 出现滚动条
            document.removeEventListener("touchmove", mo, false);
            this.webIsLock = false;
        },
        //获取文字的字符长度 中文占位2,英文及数字1
        getStrChrLength(str) {
            let chrLength = 0;
            let strLength = str.length;
            for (let i = 0; i < strLength; i++) {
                let a = str.charAt(i);
                chrLength++;
                if (escape(a).length > 4) {
                    //中文字符的长度经编码之后大于4
                    chrLength++;
                }
            }
            return chrLength;
        },
        getScrollNum() {
            return document.documentElement.scrollTop || document.body.scrollTop;
        },
        //监听是否打开软键盘
        listenerWindowResize() {
            const clHeight = window.innerHeight;
            this.defaultHeight = clHeight;
            window.onresize = () => {
                this.isOpenKeyboard = this.defaultHeight > window.innerHeight;
            };
        }
    }
};
export default minxinObj;
