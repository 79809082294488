// import Vue from 'vue';
import { Loading as loading } from 'element-ui';

class Loading {
  constructor() {
    this.Loading = loading;
  }

  open() {
    return this.Loading.service({
      lock: false,
      text: '',
      // spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.1)',
    });
  }

  close() {
    this.open().close();
  }
}
export const LoadingFn = new Loading();
export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$Loading = LoadingFn;
  },
};
