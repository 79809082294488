export default [
  {
    path: '/sys',
    name:'sys',
    component: ()=>import('@/views/sys/main'),
    meta:{
      title: '用户中心'
    },
    children: [
      {
        path: 'bak',
        name:'sys_bak',
        component: ()=>import('@/views/sys/bak/list'),
        meta:{
          title: '备份管理'
        }
      },
      {
        path: 'admingroup',
        name:'sys_admingroup',
        component: ()=>import('@/views/sys/admingroup/list'),
        meta:{
          title: '管理员分组'
        },
      },
      {
        path: 'admingroup/edit',
        name:'sys_admingroup_edit',
        component: ()=>import('@/views/sys/admingroup/edit'),
        meta:{
          title: '编辑员分组'
        },
      },
      {
        path: 'admin',
        name:'sys_admin',
        component: ()=>import('@/views/sys/admin/list'),
        meta:{
          title: '管理员设置'
        }
      },
      {
        path: 'admin/edit',
        name:'sys_admin_edit',
        component: ()=>import('@/views/sys/admin/edit'),
        meta:{
          title: '编辑管理员'
        }
      }
    ],
  },
]
